<template>
    <section id="ProjGnammy">
        

        <div class="container">
        
        <!-- PAGE TITLE -->
            <page-title 
                title="Gnammy App" 
                subtitle="Una nonna digitale. Progetto di tesi." 
            />
        <!-- --------------------- -->

        <!-- BOX WITH TEXT AND IMG -->
            <!-- Img is a path and start automatically from imgs folder.. if want find image in a folder inside imgs folder u need to specify which folder before img name (folder/name.jpg) -->
            <!-- imgType: use cover or contain -->
            <text-and-img title='Abstract' imgType="contain" img='proj-gnammy/branding/logo.svg'>
                A seguito di un interesse personale (innovare e rendere più smart la vita di tutti i giorni),
                la tesi si prefigge di sviluppare un nuovo concept inerente al settore alimentare, particolarmente incentrata sui supermercati, innovando stile di utilizzo e consumo.
                <br><br>
                L’enorme diffusione degli smartphone ha portato all’evoluzione di molti aspetti della vita quotidiana, dovuta a <strong>nuove app che generano nuovi stili di vita</strong>; si può notare però che l’interazione del cliente con gli scaffali e i packaging è rimasta pressoché la stessa da quando il primo supermarket venne aperto a Memphis nel 1916. E nonostante il mercato offra molte possibilità di innovazione nel campo alimentare per le applicazioni, si nota una <b>mancanza di innovazione su molti aspetti</b>.
            </text-and-img>
        <!-- --------------------- -->

        <!-- BOX WITH TEXT AND IMG -->
            <!-- Img is a path and start automatically from imgs folder.. if want find image in a folder inside imgs folder u need to specify which folder before img name (folder/name.jpg) -->
            <!-- imgType: use cover or contain -->
            <text-and-img title='Obiettivo' imgType="cover" img='proj-gnammy/branding/Iphone cover.webp'>
                A livello metodologico il progetto parte da una ricerca qualitativa e/o quantitativa che permette di definire le strategie da utilizzare, le tecnologie applicabili, studio del target, i motivi di interesse per le aziende nel progetto e distribuire la creatività finalizzata alla realizzazione di diversi output: 
                <br><br>
                <ul>
                    <li>Un app che permetta al cliente di selezionare al meglio i cibi di cui necessita per determinate preparazioni e/o suggerirle all’interno del supermercato attraverso l’interazione con gli scaffali al supermercato</li>
                    <li>Un sistema che permetta alle aziende alimentari di entrare a conoscenza di alcuni dati e utilizzarli per migliorare il proprio servizio e vendite</li>
                    <li>Lo studio di una Brand Identity adeguata allo sviluppo dell’app e alla conseguente applicazione sugli scaffali e all’interno della stessa</li>
                </ul><br><br>
                Il progetto vuole utilizzare un linguaggio moderno e originale, con assoluta fruibilità del servizio e semplicità d’uso.
            </text-and-img>
        <!-- --------------------- -->

        <!-- READ THE WEBSITE OR MAGAZINE -->
        <ReadThe
                title="Sfoglia il libro di tesi"
                :website="false"
                webUrl=""

                :magazine="true"
                :pages="pages /** Send an Array of pages images (all at same resolution)*/" 
                :startPage="null /** Insert start-page must be 'null' or number >= 1 */"
                :singlePage="false /** single-page format */"
                :haveCover="true /** Set true if your book have a cover */"
            />
        <!-- ---------------------------- -->

        <!-- PHOTO SHOWREEL -->
        <Showreel
                title="Branding"
                :imgs="[
                    //Find is a path and start automatically from imgs folder..
                    //if want find image in a folder inside imgs folder u need to specify which folder before img name
                    //(folder/name.jpg)
                    {
                        find: 'proj-gnammy/branding/logo.svg',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-gnammy/branding/corporate_mockup.webp',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-gnammy/branding/sacchetto gnammy.webp',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-gnammy/branding/Iphone cover.webp',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-gnammy/branding/Instagram-Gnammy.webp',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-gnammy/branding/campagna.webp',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-gnammy/branding/icone-stagionali.webp',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-gnammy/branding/QR-code-scansionami.webp',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                ]"
            />
        <!-- --------------------- -->

        <!-- PHOTO SHOWREEL -->
            <Showreel
                title="Smartphone App Screenshots"
                :imgs="[
                    //Find is a path and start automatically from imgs folder..
                    //if want find image in a folder inside imgs folder u need to specify which folder before img name
                    //(folder/name.jpg)
                    {
                        find: 'proj-gnammy/app/app-1.webp',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-gnammy/app/app-2.webp',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-gnammy/app/app-3.webp',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-gnammy/app/app-4.webp',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-gnammy/app/app-5.webp',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-gnammy/app/app-6.webp',
                        imgType: 'contain',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                ]"
            />
        <!-- --------------------- -->

        </div>
    </section>
</template>

<script>
import PageTitle from '../../components/small/PageTitle.vue'
import Showreel from '../../components/works-project/Showreel.vue'
import TextAndImg from '../../components/works-project/TextAndImg.vue'
import ReadThe from '../../components/works-project/ReadThe.vue'

export default {
    name: 'Template',
    components: {
        PageTitle,
        Showreel,
        TextAndImg,
        ReadThe,
    },
    metaInfo: {
        title: 'Project',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: 'this Project',
        }],
    },
    data(){
        return{
            //use the link to imgs -- put the files in src/book/folder and will be see when website is uploaded
            pages: [],
        }
    },
    methods: {
        //
    },
    mounted(){
        this.pages.push("https://arisgoi.it/books/book-tesi/0-front.webp");
        this.pages.push("https://arisgoi.it/books/book-tesi/0-white.webp");

        for (let i = 1; i <= 94; i++){
            this.pages.push(`https://arisgoi.it/books/book-tesi/tesi-book-${i}.webp`)
        }

        this.pages.push("https://arisgoi.it/books/book-tesi/0-white.webp");
        this.pages.push("https://arisgoi.it/books/book-tesi/0-back.webp");
    },
}
</script>


<style scoped lang="scss">
@import '@/scss/_variables.scss';

</style>